// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import VueMaterial from 'vue-material';
import Login from './Login.vue';
import 'vue-material/dist/vue-material.min.css';

/* eslint-disable no-new */
/* eslint-disable no-restricted-globals */
// ログインの場合はロードする
if ((location.pathname.indexOf('/login') === 0)) { // ログインの場合だけvueをエントリーする
  Vue.config.productionTip = false;
  Vue.use(VueMaterial);

  // 要素が表示されたときにフォーカスする
  // 「v-focusoninsert」を指定することで使用する
  Vue.directive('focusoninsert', {
    inserted: (el: HTMLElement) => {
      el.focus();
      (el as HTMLInputElement).select();
    }
  });

  new Vue({
    el: '#login',
    components: { Login },
    template: '<Login/>'
  });
}
